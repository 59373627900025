<template>
  <div>

    <parallax image="landing-yoga.jpg"></parallax>

    <module anchor="events">
      <h1>Yoga Events</h1>
      <events :params="{type: 'yoga'}"/>
    </module>

    <module color="green" anchor="offer">
      <h1>Angebot</h1>
      <div class="columns">
        <div>
          <h2>Hatha Yoga</h2>
          <p>
            Hier bekommst Du die Grundlagen der Hatha Yogahaltungen, Atemübungen und Entspannungsmethoden vermittelt und
            lernst die wohltuenden Wirkungen von Yoga in der ganzen Fülle kennen.
          </p>
          <p>
            Die Asanas dehnen und kräftigen Muskulatur, Sehnen und Gelenke, die Atemübungen und Entspannungstechniken
            beruhigen den gestressten Geist.
            Das Ziel ist mehr Flexibilität in der Wirbelsäule und eine Harmonisierung des gesamten Nervensystems.
            Nach kurzer Zeit wirst Du dich über ein völlig neues Körpergefühl und einen gelasseneren Geist freuen.
          </p>
          <p>
            Wir beginnen die Stunde jeweils mit einer aktiven,
            also Yang orientierten Praxis, um dann gegen Ende
            der Praxis in die ruhige Yin Energie- und Praxis überzugehen.
          </p>
          <p>
            Ich biete Lektionen für EinsteigerInnen sowie für fortgeschrittene Yogis & Yoginis an.
          </p>
        </div>
        <div>
          <h2>Yin Yoga Sadhana</h2>
          <p>
            Yoga erleben viele Menschen in der westlichen Welt nach wie vor nur auf der körperlichen Ebene der Asanas.
            Es geht auch hier um Leistung, Vergleich und Workout, um tolle Instagram Bilder von anspruchsvollen Yoga
            Posen. Dabei geht es im Yoga um die Reise in die Tiefe.
          </p>
          <p>
            Yoga ist Transformation. Yoga kann Dich, wenn Du es erlaubst, in ein Leben führen, das von tiefer Weisheit
            und tiefem Frieden beseelt ist.
          </p>
          <p>
            Yoga ist ein Ritual, ein Gebet.
            Eine bewusste Entscheidung, nicht nur eine Beziehung zu deinem Körper aufzubauen, sondern Dich als
            ganzheitliches Wesen kennenzulernen.
          </p>
          <p>
            Yoga, besonders auch das ruhige und stille Yin Yoga, können Dir helfen mehr Gleichgewicht und innere Ruhe
            in deinem Leben zu etablieren.
          </p>
          <p>
            Yoga ist ein Weg, um deine Intuition und deine innere Weisheit zu stärken und Dich auf einen bewussten
            und achtsamen Weg zu Dir selbst zurückzuführen.
          </p>
          <p>
            Eine solche Sadhana beinhaltet jeweils eine stille-, sinnliche- und meditative Yin Yoga Praxis bei
            Kerzenschein. Darüber hinaus werden uns diverse Atemtechniken (Pranayama) sowie das gemeinsame Rezitieren
            oder Singen von Mantren dabei unterstützen, zur Ruhe zu kommen und tiefen inneren Frieden zu finden.
          </p>
          <p>
            Mit einer stärkenden und nährenden Tasse qualitativ hochwertigem, reinen Kakao werden wir diese spirituelle
            Praxis gemeinsam abschliessen.
          </p>
        </div>
        <div>
          <h2>Yin Yoga</h2>
          <p>
            Die Bezeichnung „Yin Yoga“ leitet sich von dem chinesischen Konzept von Yin und Yang ab.
            Diese Philosophie beschreibt Yin und Yang
            als entgegengesetzte Kräfte oder Prinzipien:
            Während Yin die weibliche, ruhige, weiche und passive Energie repräsentiert, verkörpert Yang den männlichen,
            dynamischen und aktiven Gegenpart.
          </p>
          <p>
            In anatomischer Hinsicht steht Yang für die Muskeln
            und Yin für das Bindegewebe wie Sehnen und Bänder.
            Yin Yoga ist ein ruhiger, passiver Yoga-Stil, bei dem die Asanas (Körperhaltungen) grösstenteils liegend und
            sitzend ausgeführt und zwischen drei und sieben Minuten lang gehalten werden. Im Yin Yoga werden die Posen
            mit
            wenig bis keiner Muskelanstrengung ausgeführt.
          </p>
          <p>
            Auf psychischer Ebene wirkt Yin Yoga daher ähnlich wie eine stille Meditation: Während der lang gehaltenen
            Körperhaltungen kommt der Geist zur Ruhe. Yin Yoga eignet sich daher wunderbar um Stress abzubauen und
            vorzubeugen.
          </p>
          <p>
            Auf körperlicher Ebene werden die Muskeln, Bänder
            und Sehnen gedehnt. Durch das lange Halten der Asanas wird auch das tief liegende Bindegewebe erreicht
            und verklebte Faszien werden aufgelockert.
          </p>
          <p>
            Yin Yoga ist ein Yogastil, der für Einsteiger sowie für geübte Yogis & Yoginis gleichermassen geeignet ist.
            Yin Yoga ist auch eine perfekte Ergänzung zu allen Yang orientierten Yogastilen sowie zu anderen Sportarten.
          </p>
        </div>
        <div>
          <h2>Outdoor Yoga</h2>
          <p>
            Das Sanskrit Wort Yoga bedeutet so viel wie Verbindung und Einheit.
          </p>
          <p>
            Wenn wir draussen Yoga praktizieren, können wir ganz tief in Verbindung gehen. Wir erleben ein Verbundensein
            mit der Natur, mit ihren Rhythmen und den Elementen.
            Dadurch kommen wir wieder mehr und mehr in Verbindung zu uns selbst, zu unserem wahren Wesenskern.
          </p>
          <p>
            Yoga ist sowohl eine Methode mit diversen Körper- und Atemübungen als auch ein Zustand.
            Ein Zustand der Verbundenheit und der Einheit mit dem universellen Ganzen.
          </p>
          <p hidden>
            Und wo und wie können wir diese Verbundenheit besser er-leben und in Kontakt gehen als in der Natur?
            Eingebettet in die Kräfte der Natur nutzen wir die «Methode» - also Körperübungen,-Atemübungen und
            Meditationen - um diese Einheit zu erfahren.
          </p>
          <p>
            Dank tiefer Atmung an der frischen Luft, dem direkten Bodenkontakt sowie dem natürlichen Licht fühlen wir
            uns
            bereits nach kurzer Zeit gestärkt und entspannt zugleich.
          </p>
          <p>
            Wir praktizieren meist direkt bei der Insel Werd, einem wunderschönen und magischen Kraftort, wo der
            Untersee
            in den Rhein übergeht. So sind wir in direktem Kontakt mit allen Elementen –
            in der äusseren sowie in unserer inneren Natur.
          </p>
        </div>
      </div>
    </module>

    <module anchor="timetable" class="timetable">
      <h1>Stundenplan</h1>
      <v-row>
        <v-col cols="4" md="2">
          Dienstag
        </v-col>
        <v-col cols="8" md="3">
          09:00 bis 10:15 Uhr
        </v-col>
        <v-col cols="12" md="4">
          Outdoor Yoga
        </v-col>
        <v-col cols="12" md="3">
          Eschenz
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="2">
          Dienstag
        </v-col>
        <v-col cols="8" md="3">
          19:00 bis 20:30 Uhr
        </v-col>
        <v-col cols="12" md="4">
          Yin Yoga
        </v-col>
        <v-col cols="12" md="3">
          <a href="#locations">Raum 18</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="2">
          Mittwoch
        </v-col>
        <v-col cols="8" md="3">
          19:00 bis 20:15 Uhr
        </v-col>
        <v-col cols="12" md="4">
          Hatha Yoga mit Yin Elementen
        </v-col>
        <v-col cols="12" md="3">
          <a href="#locations">Raum 18</a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="2">
          Donnerstag
        </v-col>
        <v-col cols="8" md="3">
          09:30 bis 10:45 Uhr
        </v-col>
        <v-col cols="12" md="4">
          Hatha Yoga
        </v-col>
        <v-col cols="12" md="3">
          <a href="#locations">Raum 18</a>
        </v-col>
      </v-row>
      <div class="pt-12">
        Weitere ausserordentliche Yogastunden sind unter <a href="#events">Events</a> zu finden.
      </div>
    </module>

    <module>
      <masonry :images="images"></masonry>
    </module>

    <module color="green" anchor="private">
      <h1>Privatlektion</h1>
      <p>
        Die Privatlektion erlaubt es dir, dich deinen ganz persönlichen Themen, körperlichen Möglichkeiten und Zielen
        anzunehmen. Die Körperübungen (Asanas), Atemtechniken (Pranayama) sowie Meditationen werden ganz individuell für
        dich ausgewählt und zusammengestellt.
      </p>
      <p>
        Auch wenn du neu mit Yoga starten möchtest und dir einen Überblick über die wichtigsten Asanas und Begriffe
        verschaffen möchtest, ist eine Privatlektion eine tolle Möglichkeit , um einen sanften und doch fundierten
        Einstieg zu finden.
      </p>
      <p>
        Für Yogis & Yoginis, die regelmässig alleine zuhause praktizieren, ist eine Privatlektion ebenfalls sehr
        empfehlenswert, da sich mit der Zeit auch (Fehl-) Haltungen einschleichen können, welche wir in einer solchen
        Lektion gemeinsam erkennen und korrigieren können.
      </p>
    </module>

    <module anchor="prices">
      <h1>Preise</h1>
      <v-row>
        <v-col cols="12" lg="7" xl="6">
          <v-row>
            <price title="Probelektion" price="20" info="Beliebige Lektion"></price>
            <price title="Privatlektion" price="95" info="60 Minuten"></price>
          </v-row>
          <h2>Trimester Abonnements</h2>
          <v-row>
            <price title="75 Minuten" price="27.50" :per-lesson="true" lessons="ca. 16 Lektionen"
                   info="Indoor"></price>
            <price title="90 Minuten" price="30" :per-lesson="true" lessons="ca. 16 Lektionen"
                   info="Indoor"></price>
            <price title="75 Minuten" price="19.50" :per-lesson="true" lessons="ca. 16 Lektionen"
                   info="Outdoor"></price>
          </v-row>
          <h2>Drop In</h2>
          <v-row>
            <price title="75 Minuten" price="30" lessons="1 Lektion" info="Indoor"></price>
            <price title="90 Minuten" price="32" lessons="1 Lektion" info="Indoor"></price>
            <price title="75 Minuten" price="25" lessons="1 Lektion" info="Outdoor"></price>
          </v-row>
        </v-col>
        <v-col cols="12" lg="5" xl="6" class="information">
          <h2 class="mt-lg-0">Allgemeine Informationen</h2>
          <ul>
            <li>
              Die einmaligen Yin Yoga Veranstaltungen in Mammern finden ab 3 TeilnehmerInnen statt und sind auf 4
              TeilnehmerInnen begrenzt.
              Bis 10 Tage vor dem Termin kannst Du die Buchung kostenfrei stornieren. Absagen die später eingehen können
              nicht zurückerstattet werden.
              Sollte eine Veranstaltung aufgrund zu geringer Teilnehmerzahl abgesagt werden, werden die Kosten
              zurückerstattet.
            </li>
            <li>Für eine Probelektion melde Dich gerne bei mir.</li>
          </ul>
          <h2>Zahlungsmodalitäten</h2>
          <ul>
            <li>
              Für Abonnements stelle ich Dir gerne eine Rechnung zur Zahlung per Überweisung aus. Diese ist im Voraus
              zu bezahlen.
            </li>
          </ul>
        </v-col>
      </v-row>
    </module>

    <module color="green" anchor="locations">
      <h1>Location</h1>
      <v-row>
        <v-col cols="12" md="6">
          <h2>Raum 18</h2>
          <p>
            Kaltenbacherstrasse 18<br>
            8260 Stein am Rhein
          </p>
          <h3>Mit dem ÖV</h3>
          <p>Vom Bahnhof Stein am Rhein in ca. 2 Minuten erreichbar</p>
          <h3>Mit dem Auto</h3>
          <p>Parkplätze gleich nach der Einfahrt zum Fabrikgelände links.<br>Weitere Parkplätze beim Coop oder Bahnhof.
          </p>
        </v-col>
      </v-row>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Masonry from "./Masonry";
  import Parallax from "./Parallax";
  import Timetable from "./Timetable";
  import Price from "./Price";
  import Events from "@/components/Public/Events.vue";

  export default {
    head: {
      title: "Yoga",
      meta: [{
        vmid: "description",
        name: "description",
        content: "In unserem grossen, hellen und ruhigen Yogaraum bieten wir eine grosse Auswahl an Yogakursen- und Yogastilen an. Ihr findet Angebote für Einsteiger und fortgeschrittene Yogis."
      }]
    },
    components: {
      Events,
      Price,
      Module,
      Masonry,
      Parallax,
      Timetable
    },
    data() {
      return {
        images: [
          "yoga-1.jpg",
          "yoga-3.jpg",
          "yoga-4.jpg",
          "yoga-5.jpg",
          "yoga-6.jpg",
          "yoga-7.jpg",
          "yoga-8.jpg",
          "yoga-9.jpg",
          "yoga-11.jpg",
          "yoga-12.jpg"
        ]
      }
    }
  }

</script>

<style lang="scss">

  @import "src/assets/styles/colors";

  @media (max-width: 959px) {
    .timetable .row > div {
      &:nth-of-type(3), &:nth-of-type(4) {
        padding-top: 0;
      }
    }
  }

</style>
